import { Flex } from 'antd';
import React, { Fragment, useState } from 'react';
import { Badge, Button, Sidebar, Tooltip } from '../../../common';
import CreatePatientContactModal from '../../modals/patient-contact-modal/CreatePatientContactModal';
import LeadContactSidebarBody from './LeadContactSidebarBody';
import { usePatientContactsContext } from '../../../contexts/patient-contact-context';

const LeadContactSidebar = ({ patientId = '' }) => {
  const { patientContacts } = usePatientContactsContext();
  const [contactsData, setContactsData] = useState(patientContacts);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const contactsCount = contactsData?.length ?? 0;

  const getContactsTitle = () => (
    <Flex align="center" gap={10}>
      <span>{'Contacts'}</span>
      <Badge count={contactsCount} />
    </Flex>
  );

  const onSuccess = (updatedContacts) => setContactsData(updatedContacts);

  const getContactDescription = () => <CreatePatientContactModal patientId={patientId} onSuccessCreate={onSuccess} />;

  return (
    <Fragment>
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        heading={getContactsTitle()}
        description={getContactDescription()}
        content={<LeadContactSidebarBody contactsData={contactsData} onSuccessEdit={onSuccess} />}
      />

      <Tooltip title="Manage Contact" show={!sidebarOpen}>
        <Button icon={'PiUserRectangleThin'} size="medium" onClick={() => setSidebarOpen((prevState) => !prevState)} />
      </Tooltip>
    </Fragment>
  );
};

export default LeadContactSidebar;
