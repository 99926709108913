import React from 'react';
import { Col, Empty, Flex, Row } from 'antd';
import styles from './LeadContact.module.scss';
import DisplayDateTime from '../../../utils/time/display_time';
import StatusButton from '../../../utils/status/status_button';
import { Button } from '../../../common';
import EditPatientContactModal from '../../modals/patient-contact-modal/EditPatientContactModal';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';
import DeletePatientContactModal from '../../modals/patient-contact-modal/DeletePatientContactModal';

const ContactCard = ({ patientContact = {}, onSuccessEdit = () => {} }) => {
  return (
    <Flex vertical className={styles.contactCardContainer} gap={20}>
      <Flex align="center" justify="space-between">
        <StatusButton status={patientContact?.type} />
        <Flex gap={10}>
          <Permission models={PERMISSIONS_STRUCT.CONTACT.NAME} actions={PERMISSIONS_STRUCT.CONTACT.ACTIONS.UPDATE}>
            <EditPatientContactModal
              contactId={patientContact?.id}
              contactData={patientContact}
              onSuccessEdit={onSuccessEdit}
            />
          </Permission>
          <Permission models={PERMISSIONS_STRUCT.CONTACT.NAME} actions={PERMISSIONS_STRUCT.CONTACT.ACTIONS.DESTROY}>
            <DeletePatientContactModal contactId={patientContact?.id} onSuccessDelete={onSuccessEdit} />
          </Permission>
        </Flex>
      </Flex>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">{'Email'}</div>
          <div className="text-1">{patientContact?.email ?? '-'}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-2">{'Phone'}</div>
          <div className="text-1">{patientContact?.phone_number ?? '-'}</div>
        </Col>
      </Row>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">{'Created At'}</div>
          <div className="text-1">{DisplayDateTime(patientContact?.source_created_at) ?? '-'}</div>
        </Col>
        {patientContact?.object_class && (
          <Col span={12}>
            <div className="text-2 mb-2">{patientContact.object_class}</div>
            <div className="text-1">{patientContact?.object_count ?? '-'}</div>
          </Col>
        )}
      </Row>
    </Flex>
  );
};

const LeadContactSidebarBody = ({ contactsData = [], onSuccessEdit = () => {} }) => {
  return (
    <Flex vertical className={styles.contactsBodyContainer} style={{ minHeight: '100%' }} gap={24}>
      {contactsData?.length > 0 ? (
        contactsData?.map((patientContact) => (
          <ContactCard key={patientContact?.id} patientContact={patientContact} onSuccessEdit={onSuccessEdit} />
        ))
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default LeadContactSidebarBody;
