import { Flex, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RenderFilters from './render-filters/RenderFilters';
import { convertQueryObjToString, getFiltersArray, mapQueryStringToFilterObject } from '../../utils/filters-util';
import { isEmptyObject } from '../../utils';
import { Button, Card, Sidebar } from '../../common';
import AdvancedFilters from './advanced-filters/AdvancedFilters';
import styles from './Filters.module.scss';
import QuickFilters from './quick-filters/QuickFilters';

const Filters = ({ filtersList = [], fetch = () => {}, sourceClass = '', rootPathUrl = '/admin', loading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);
  const [filtersObject, setFiltersObject] = useState({});
  const [selectedInputFilter, setSelectedInputFilter] = useState({});
  const { filters, advancedFilters } = getFiltersArray(filtersList);

  const fetchData = () => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj };
    setFiltersObject(updatedFilterObject);
    isEmptyObject(updatedFilterObject) ? fetch() : fetch(updatedFilterObject);
  };

  const resetFilters = () => {
    navigate(rootPathUrl);
  };

  const handleFiltersChange = (updatedFilterObject) => {
    navigate({
      pathname: location.pathname,
      search: convertQueryObjToString(updatedFilterObject),
    });
  };

  const getSidebarHeading = () => {};

  useEffect(() => {
    fetchData();
  }, [location.search]);

  return (
    <Flex vertical gap={24}>
      <Sidebar
        sidebarOpen={filtersSidebarOpen}
        setSidebarOpen={setFiltersSidebarOpen}
        heading={'Filters'}
        description={'Advanced Filters'}
        content={
          <AdvancedFilters
            location={location}
            navigate={navigate}
            baseFilters={advancedFilters}
            filtersObject={filtersObject}
            setFiltersObject={setFiltersObject}
            handleFiltersChange={handleFiltersChange}
          />
        }
      />
      <Card className={styles.filtersContainerCard}>
        <Row gutter={[14, 14]} className="d-flex w-100" wrap>
          {filters?.length &&
            filters.map((item, index) => (
              <Col
                xs={24}
                sm={12}
                md={item?.isLargeFilter ? 5 : 5}
                lg={item?.isLargeFilter ? 8 : 4}
                xl={item?.isLargeFilter ? 8 : 4}
                key={index}
              >
                <RenderFilters
                  item={item}
                  filtersObject={filtersObject}
                  setFiltersObject={setFiltersObject}
                  loading={loading}
                  handleFiltersChange={handleFiltersChange}
                />
              </Col>
            ))}
          {advancedFilters.length > 0 && (
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Button
                text={'More Filters'}
                icon={'PiSlidersHorizontal'}
                iconPosition={'end'}
                size={'large'}
                loading={loading}
                onClick={() => {
                  setFiltersSidebarOpen((prev) => !prev);
                }}
              />
            </Col>
          )}
        </Row>
      </Card>
      {!!sourceClass && <QuickFilters sourceClass={sourceClass} resetFilters={resetFilters} />}
    </Flex>
  );
};

export default Filters;
