export const PERMISSION_MODELS = Object.freeze({
  USER: 'user',
  PRODUCT: 'product',
  ORDER: 'order',
  LEAD: 'lead',
  SUBSCRIPTION: 'subscription',
  CASE: 'case',
  PATIENT: 'patient',
  PRESCRIPTION: 'prescription',
  STATUS: 'status',
  TICKET: 'ticket',
  VOUCHER: 'voucher',
  QUESTIONNAIRE: 'questionnaire',
  ACTIVITY: 'activity',
  NOTE: 'note',
  CONTACT: 'contact',
});

const DEFAULT_PERMISSION_ACTIONS = {
  SHOW: 'show',
  INDEX: 'index',
  CREATE: 'create',
  UPDATE: 'update',
};

export const PERMISSIONS_STRUCT = {
  LEAD: {
    NAME: PERMISSION_MODELS.LEAD,
    ACTIONS: {
      MERGE_LEAD: 'merge_lead',
      SYNC_LEAD: 'sync',
      RESOLVE_TICKET: 'resolve_ticket',
      OPEN_TICKET: 'open_ticket',
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  PATIENT: {
    NAME: PERMISSION_MODELS.PATIENT,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  ORDER: {
    NAME: PERMISSION_MODELS.ORDER,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  CASE: {
    NAME: PERMISSION_MODELS.CASE,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  STATUS: {
    NAME: PERMISSION_MODELS.STATUS,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  PRODUCT: {
    NAME: PERMISSION_MODELS.PRODUCT,
    ACTIONS: {
      SYNC_PRODUCT: 'sync',
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  SUBSCRIPTION: {
    NAME: PERMISSION_MODELS.SUBSCRIPTION,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  USER: {
    NAME: PERMISSION_MODELS.USER,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  TICKET: {
    NAME: PERMISSION_MODELS.TICKET,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  QUESTIONNAIRE: {
    NAME: PERMISSION_MODELS.QUESTIONNAIRE,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  VOUCHER: {
    NAME: PERMISSION_MODELS.VOUCHER,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  ACTIVITY: {
    NAME: PERMISSION_MODELS.ACTIVITY,
    ACTIONS: {
      FETCH_ACTIVITIES: 'fetch_activities',
    },
  },
  NOTE: {
    NAME: PERMISSION_MODELS.NOTE,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  CONTACT: {
    NAME: PERMISSION_MODELS.CONTACT,
    ACTIONS: {
      DESTROY: 'destroy',
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
};
